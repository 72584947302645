<template>
  <div id="promise-head">
    <div class="container" v-cloak>
      <div class="title">
        <h2>{{ title }}</h2>
      </div>

      <div class="text-area">

        <div
            v-on:mouseenter="setEntered(content.title)"
            v-for="content in webLikes"
            :key="content.title"
            class="text-area_col">
          <div class="text-area_col__illustration">
            <img
                v-if="content.image"
                :src="imageUrlFor(content.image)"
                alt="illustration"
            />
          </div>

          <promise-animated-title  :title="content.title"
                                  :entered="enteredElement"></promise-animated-title>

          <p>{{ content.text }}</p>
        </div>
      </div>

      <div class="round-button" id="promise-button">
        <button @click="$emit('scrollToPersuade')">
          next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import PromiseAnimatedTitle from "@/components/promise/promiseAnimatedTitle";


const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "webLike"] { _id, title , text, "image": smallImage {
asset->{ _id, url }
}
}`;

export default {
  name: "promiseComp",
  components: {PromiseAnimatedTitle},
  props: ["innerWith"],
  emits: ["scrollToPersuade"],
  watch: {},
  data() {
    return {
      title: "Things I like about a website:",
      webLikes: null,
      enteredElement: ''
    };
  },
  mounted() {
    this.fetchData();
  },
  beforeUnmount() {
  },
  methods: {
    async fetchData() {
      try {
        this.webLikes = await sanity.fetch(query);
      } catch (e) {
        console.log(e);
      }
    },
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    setEntered(title) {
      this.enteredElement = title
    }
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}

.container {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 2rem;
  background: url("../../assets/images/PNG/Artboard 1.png") repeat;
  background-size: contain;

  color: var(--background-primary-dark);
  min-height: 100vh;

  @media screen and (min-width: 40em) {
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 2rem;
  }
}

.title {
  margin: 0 4rem;

  h2 {
    font-size: 3rem;


    @media screen and (min-width: 40em) {
      font-size: 4rem;
    }
  }
}

.text-area {
  @media screen and (min-width: 40em) {
    display: grid;
    padding: 0 3rem;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  &_col {
    margin: 2rem;
    padding: 0 2rem;
    border-radius: 3rem;
    border: dashed var(--color-secondary);
    background-image: radial-gradient(
            circle,
            var(--color-my-white),
            rgba(218, 232, 242, 0.4)
    );
    min-height: 40rem;
    transition: all .3s linear;

    @media screen and (min-width: 40em) {
    }

    &:hover {
      background-image: radial-gradient(circle, white, var(--color-six));
    }

    &__illustration {
      margin: 3rem auto;

      img {
        height: 3.5rem;
      }
    }

    p {
      font-weight: 500;
      font-style: normal;
      font-size: 2rem;
    }
  }
}

.round-button {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 3rem;
  @media screen and (min-width: 40em) {
    margin-top: 0;
    margin-bottom: 3vh;
  }

  button {
    background-color: var(--color-my-white);
    border-radius: 8rem;
    width: 10rem;
    height: 10rem;
    border: dashed 1px black;
    color: black;
    cursor: pointer;
    box-shadow: var(--shadow-smaller);
    transition: 0.2s ease;
    position: relative;
    overflow: hidden;

    &:hover {
      transform: scale(1.05);
      box-shadow: var(--shadow-small);
    }

    &:active {
      box-shadow: none;
      transform: scale(0.97);
    }
  }
}

</style>
