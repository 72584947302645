<template>
  <div class="title-container">
    <transition @enter="enterAnimate">
      <div class="ripple" v-show="enteredElement"></div>
    </transition>
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "promiseAnimatedTitle",
  props: {title: String, entered: String},
  computed: {
    enteredElement() {
      return this.entered === this.title
    }
  },
  methods: {
    enterAnimate(el, doneFn) {
      console.log(el)
      el.animate([
        {},
        {
          transform: 'translate(-50%, -50%) scale(1.6)', opacity: 0,
          border: 'solid 20px rgba(218, 232, 242, .6)',
          backgroundColor: 'rgba(218, 232, 242, .6)'

        }
      ], {duration: 600, easing: 'ease-out'})
      doneFn()
    }
  }
}
</script>

<style scoped lang="scss">

.title-container {
  .ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    filter: blur(.6rem);
    transform: translate(-50%, -50%) scale(0);
    border: solid 20px rgba(253, 253, 253, 1);
    background-color: rgba(253, 253, 253, 1);
  }

  position: relative;

  h3 {
    font-family: reross-quadratic, sans-serif;

    font-size: 3rem;
    text-align: center;
  }
}
</style>
